<template>
  <div>
    <div
      class="banner_info"
      style="
        background: url(images/10_02.jpg) center no-repeat;
        background-size: cover;
      "
    >
      <img src="images/10_03.png" />
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box">
            <dl>
              <dt>联系我们</dt>
              <dd>contact us</dd>
            </dl>
            <ul>
              <li
                :class="{ on: index == cIndex }"
                @click="itemClick(index)"
                v-for="(item, index) in tabArray"
                :key="index"
              >
                <a href="javascript:;"></a> {{ item }}
              </li>
            </ul>
          </div>
          <div class="box">
            <dl>
              <dt>法律法规</dt>
              <dd>laws and regulations</dd>
            </dl>
            <ul>
              <li
                v-for="item in flfg.articleList"
                :key="item.id"
                @click="toDetail(item.id, flfg.name)"
              >
                <a href="javascript:;">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="right" v-if="cIndex == 0 || cIndex == 2">
          <div class="box">
            <div class="title flex">
              <div class="flex_item">
                <b>{{ detail.title }}</b>
              </div>
            </div>
            <div class="contenus" v-html="detail.content"></div>
          </div>
        </div>


        <div class="right" v-else-if="cIndex == 1">
          <div class="box">
            <div class="title flex">
              <div class="flex_item"><b>意见、建议反馈</b></div>
            </div>
            <div class="form">
              <table width="100%">
                <tr>
                  <td width="85"><span class="redstar">*</span>姓名：</td>
                  <td>
                    <input type="text" class="input" v-model="comment.name" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>邮箱：</td>
                  <td>
                    <input type="text" class="input" v-model="comment.email" />
                  </td>
                </tr>
                <tr>
                  <td><span class="redstar">*</span>移动电话：</td>
                  <td>
                    <input
                      type="text"
                      class="input"
                      v-model="comment.telephone"
                    />
                  </td>
                </tr>
                <!-- <tr>
                  <td><span class="redstar">*</span>主题：</td>
                  <td>
                    <input type="text" class="input" v-model="comment.title" />
                  </td>
                </tr> -->
                <tr>
                  <td><span class="redstar">*</span>内容：</td>
                  <td>
                    <textarea
                      style="border: 1px solid #d8eaff; background: #f5f5f6"
                      cols="60"
                      rows="10"
                      v-model="comment.content"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type="button" class="btn" @click="send">
                      发送
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      lxwm: [],
      flfg: [],
      detail: "",
      cIndex: 0,
      tabArray: ["中心联系方式", "意见、建议反馈", "监督投诉"],
      comment:{
        "name": "",
        "telephone": "",
        "email": "",
        // "title": "",
        "content": ""
      }
    };
  },
  methods: {
    toDetail(id, name) {
      this.$router.push({
        path: "/view",
        query: { articleId: id, name: name },
      });
    },
    itemClick(index) {
      this.cIndex = index;
      this.getDetail();
    },
    async getDetail() {
      let res = await this.$api.findArticleByColumn({
        columnName: this.tabArray[this.cIndex],
      });
      this.detail = res.data.data;
    },
    async send(){
      let res = await this.$api.create(this.comment);
      if(res.data.code == 200){
        this.$message({
          message: '留言成功',
          type: 'success'
        });
        for(let item in this.comment){
          this.comment[item] = ""
        }
      }else{
        this.$message.error(res.data.msg);
      }
    }
  },
  async mounted() {
    //获取左侧固定数据
    let res1 = await this.$api.getColumn({ columnName: "联系我们" });
    this.lxwm = res1.data.data[0];
    console.log(this.lxwm);
    let res2 = await this.$api.getColumn({ columnName: "法律法规" });
    this.flfg = res2.data.data[0];
    this.cIndex = this.$route.query.index || 0;
    this.getDetail();
  },
};
</script>

<style scoped>
</style>
